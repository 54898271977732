import React, {useEffect}  from 'react';
import '../../css/aboutme.css';
import { Link } from 'react-router-dom';
import FAQ from './FAQ';
import { Helmet } from "react-helmet-async";

const AboutMe = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page on load
      }, []);

  return (
    <section id="aboutMe" className='no-padding margins'>
        <Helmet>
            <title>About Me | Meet Amna | Photos by Amna</title>
            <meta name="description" content="Learn more about Amna, the photographer behind Photos by Amna and discover her journey, passion, and approach." />
            <meta name="keywords" content="about Amna, photographer bio, toronto photographer, wedding photographer, photography journey" />
            <meta name="author" content="Photos by Amna" />
            <meta name="robots" content="index, follow" />

            {/* Open Graph */}
            <meta property="og:title" content="About Me | Meet Amna | Photos by Amna" />
            <meta property="og:description" content="Learn more about Amna, the photographer behind Photos by Amna and discover her journey, passion, and approach." />
            <meta property="og:image" content="https://www.photosbyamna.com/ogimage.jpg" />
            <meta property="og:url" content="https://photosbyamna.com/about" />
            <meta property="og:type" content="website" />
            <link rel="canonical" href="https://photosbyamna.com/about" />
        </Helmet>
        <h1>BEHIND THE LENS</h1>
        <div className='col-2 padding-6-side  padding-6-up'>
            <div>
            <img className='about-me-photo' alt="Photo of Amna - Toronto Photographer" src="./assets/about-me-photo.jpg" name="Toronto Photographer" loading='lazy'/>
            </div>
            <div>
                <h4 style={{fontWeight:"bold", marginTop:"0"}}>Meet Amna</h4>
                <div className='horizontalSeperator'></div>
                    <p>
                        Photos by Amna is about documenting stories, not just capturing photographs. Having a background in design and creative technology, plus a lifelong love for art and illustration, storytelling has always been at the heart of my career.
                        And photography being a natural extension of my creative passion brings me immense joy. I can confidently say—I truly love what I do!
                        </p>
                        <p>
                        My work is a combination of thoughtful artistic composition and candid moments that capture you as you are. So when you look at your photos, I want you to feel the moment — so it's not just an image, it's a memory that the touches your heart and stays with you for a lifetime.
                    </p>
                    <p>
                        When you choose me as your photographer, I don't just provide you with a session, Im going to be providing you an experience from the moment we speak until the moment you receive your gallery.
                        From our initial consultation to the delivery of your final gallery, I'll guide you every step of the way, taking care of the details so you can simply focus on being present and enjoying your moment.
                        Whether it's a wedding, maternity, or intimate couple session, I focus on creating a comfortable environment for you to be yourself so the moments can happen naturally.
                    </p>

            </div>
        </div>
        {/* <h2>Follow my Jouney</h2> */}
        <div className='col-2  padding-6-side'>
            <div className='blog-post'>
                <Link to={`/portfolio`}>
                        <div className='blog-post-thumbnail'>
                        <img 
                            src='/assets/unscripted.avif' 
                            alt="through-my-lens"
                        />
                        </div>
                        <div className='blog-post-text'>
                        
                    <h3>Explore Portfolio</h3>
                    <p>Browse a crafted portfolio featuring weddings, couples, maternity, branding, and travel photography. Based in Toronto, telling stories worldwide.</p>
                    {/* <p className='blog-post-date'>{post.date}</p> */}
                    </div>
                </Link>
            </div>
        
            <div className='blog-post'>
                <Link to={`/blog/through-my-lens`}>
                        <div className='blog-post-thumbnail'>
                        <img 
                            src='/assets/blog/thumbnails/through-my-lens.avif' 
                            alt="through-my-lens"
                        />
                        </div>
                        <div className='blog-post-text'>
                        
                    <h3>Through My Lens: A Toronto Wedding Photographer’s Journey</h3>
                    <p>Discover how my passion for photography blossomed in the heart of Toronto, capturing love, life, and unforgettable moments.</p>
                    {/* <p className='blog-post-date'>{post.date}</p> */}
                    </div>
                </Link>
            </div>
            <div className='horizontalSeperator'></div>
        </div>

        
        <section className="no-padding no-margins">
            <br></br>
            <br></br>
        <FAQ/>
        </section>
        
    </section>
  );
};

export default AboutMe;
