import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../../css/services.css";
// import WorldMapWithPlane from "../../utils/WorldMapWithPlane";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import { Helmet } from "react-helmet-async";


const Services = () => {
  const location = useLocation();
  const pricingRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  useEffect(() => {
    if (location.state?.activeCategory) {
      // Check if activeCategory exists and trigger scroll
      pricingRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state?.activeCategory]);

  const reasons = [
    { title: "Customized Photography", description: "Capture moments in your unique style with expert guidance." },
    { title: "All-Inclusive Packages", description: "Unlimited professionally edited photos, clear pricing, and no hidden fees." },
    { title: "Premium Experience", description: "Top-tier quality, stress-free sessions, and quick delivery." },
    { title: "Flexible & Reliable", description: "Available for any location with easy rescheduling and satisfaction guarantees." },
  ];
  

  const serviceAreas = ["Toronto", "Mississauga/Milton/Oakville", "Hamilton/Guelph/Kitchener/London", "Niagara/St Catherines" , "North-York/Vaughan/Markham/NewMarket/Aurora", "Worlwide"];

  return (
    <div id="services-page">
      <Helmet>
        <title>Photography Services | Packages, Pricing & FAQs | Photos by Amna</title>
        <meta name="description" content="Explore photography services, packages, and pricing. Find answers to FAQs and book your session with Photos by Amna today." />
        <meta name="keywords" content="photography services, wedding photography, portrait photography, pricing, packages, FAQs, toronto photographer" />
        <meta name="author" content="Photos by Amna" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph */}
        <meta property="og:title" content="Photography Services | Packages, Pricing & FAQs | Toronto Photographer" />
        <meta property="og:description" content="Explore photography services, packages, and pricing. Find answers to FAQs and book your session with Photos by Amna today." />
        <meta property="og:image" content="https://www.photosbyamna.com/ogimage.jpg" />
        <meta property="og:url" content="https://photosbyamna.com/services" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://photosbyamna.com/services" />
      </Helmet>
      <section className="no-padding margins">
      <div className="top-container">
        <h1 className="bigNumber">DETAILS</h1>
        <p>
          Explore thoughtfully crafted offerings to capture your most cherished moments with authenticity and artistry.
        </p>
        <div className="section-background"></div>
      </div>

      <div className="grid-row-container  fade-up">
        {/* Why Choose Us Section */}
        <div className="why-choose-us-section">
          <h3>Why Choose Us?</h3>
          <p>Here are some reasons why this photography service stands out:</p>
          <ul className="why-choose-us-list">
            {reasons.map((reason, index) => (
              <li key={index}>
                <div className="checkmark"></div>
                <div>
                  <p className="why-choose-us-title">
                    {reason.title}:
                  </p>
                  <p className="why-choose-us-description">{reason.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Service Areas Section */}
        <div className="service-area-section">
          <h3>Service Areas</h3>
          {serviceAreas.map((area, index) => (
            <p key={index}>{area}</p>
          ))}

          {/* Use the WorldMapWithPlane component */}
          {/* <WorldMapWithPlane /> */}
          <div className="worldMap"></div>
        </div>
        </div>
        
        <div className="horizontalSeperator"></div>
    </section >
        

      {/* Pricing Section */}
      <section className="no-padding margins" ref={pricingRef}>
        <Pricing/>
        <div className="spacer-2em"></div>
        <div className="horizontalSeperator"></div>
      </section>

      {/* Additional Sections */}
      <section className="process no-padding margins fade-up">
        <h2>The Experience</h2>
        <p>You're only a few steps away from preserving your perfect moments</p>
        <div className="steps">
          <div>
            <h3>01</h3>
            <h4>Initial Consultation</h4>
            <p>Let's start with a consultation call to discuss your vision, preferences, and requirements before booking.</p>
            <a className="custom-link" href="/services#contact">Contact Now</a>
          </div>
          <div>
            <h3>02</h3>
            <h4>Book Your Session</h4>
            <p>Together, we’ll plan the perfect locations, timing, and every detail to confirm your booking seamlessly.</p>
            <a className="custom-link" href="/services#pricing">See Pricing</a>
          </div>
          <div>
            <h3>03</h3>
            <h4>Big Day</h4>
            <p>On the day of your shoot, I’ll guide you through poses and moments to bring your vision to life beautifully.</p>
            <a className="custom-link" href="https://pitch.com/v/photo-locations-msbe6t" target="_blank">See Locations</a>
          </div>
          <div>
            <h3>04</h3>
            <h4>Gallery Reveal</h4>
            <p>Within 1-3 weeks, your carefully edited photos will be delivered in an online gallery for you to treasure forever.</p>
            <a className="custom-link" href="/portfolio" target="_blank">See Portfolio</a>
          </div>
        </div>
        
        <div className="horizontalSeperator"></div>
      </section>
    
      <section className="no-padding margins">
        <FAQ/>
      </section>

    </div>
  );
};

export default Services;
