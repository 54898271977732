import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How long have you been a photographer?",
      answer:
        "I've been photographing couples and weddings professionally since 2023, though my photography experience goes back much further.",
    },
    {
      question: "Will lighting or weather be an issue?",
      answer:
        "I'm comfortable working with any lighting conditions we encounter on the day of your shoot. I embrace all kinds of weather, but if it reaches a point where it would make the experience unpleasant for everyone, we can always reschedule!",
    },
    {
      question: "Do you give RAW unedited images?",
      answer:
        "I don’t provide RAW unedited images, as editing is a key part of my creative process and ensures your photos have a polished, professional finish. However, I do offer flexibility when it comes to editing styles! If you have a preference—whether it’s light and airy, warm and moody, or something in between—I’m happy to tailor the edits to match your vision while maintaining a timeless, high-quality look.",
    },
    {
      question: "How do I book a session?",
      answer:
        "You can book a session by contacting me through my website or social media platforms.",
    },
    {
      question: "How long does it take to receive the photos?",
      answer:
        "The turnaround time is quick and typically 1-3 weeks for weddings and 1 week for smaller sessions, depending on the season.",
    },
    // {
    //   question: "Do you offer prints or albums?",
    //   answer:
    //     "Yes, I offer high-quality prints and custom-designed albums. These can be added to your package or purchased separately.",
    // },
    {
      question: "What is your photography style?",
      answer: "My style is a blend of candid and timeless, focusing on genuine emotions and timeless aesthetics.",
    },
    {
      question: "What is your payment policy?",
      answer: "A 10% booking deposit is required for full-day weddings, to be paid atleast one month before the event. For all other sessions, payment can be made on the day of or the day before the shoot. I accept both cash and Interac-e-transfers.",
    },
    {
      question: "What if I need to cancel or reschedule our shoot?",
      answer: "I understand that plans change! If you need to reschedule, I’m happy to find a new date. For cancellations, policies vary based on timing, but I’ll do my best to accommodate—just let me know as soon as possible!",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section fade-up">
    <h2 className="faq-title">Frequently Asked Questions</h2>
    <div className="faq-list">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item" onClick={() => handleToggle(index)}>
          <div className="faq-question">
            <motion.div
              animate={{ rotate: activeIndex === index ? 90 : 0 }}
              transition={{ duration: 0.2 }}
              className="download-arrow"
            />
            <h4>{faq.question}</h4>
          </div>

          <AnimatePresence>
            {activeIndex === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="faq-answer"
              >
                <p>{faq.answer}</p>
              </motion.div>
            )}
          </AnimatePresence>


          <div className="horizontalSeperator"></div>
        </div>
      ))}
    </div>
  </div>
);
    // <div className="faq-section fade-up">
    //   <h2 className="faq-title">Frequently Asked Questions</h2>
    //   <div className="faq-list">
    //     {faqs.map((faq, index) => (
    //       <div key={index} className="faq-item" onClick={() => handleToggle(index)}>
    //         <div className="faq-content">
    //                 <div className="faq-question"><div className="download-arrow"></div><h4>{faq.question}</h4></div>
                
    //                 <AnimatePresence>
    //                 {activeIndex === index && (
    //                     <motion.div
    //                     initial={{ opacity: 0, scale: 0.95 }}
    //                     animate={{ opacity: 1, scale: 1 }}
    //                     exit={{ opacity: 0, scale: 0.95 }}
    //                     transition={{ duration: 0.3 }}
    //                     className="faq-answer"
    //                     >
    //                     <p>{faq.answer}</p>
    //                     </motion.div>
    //                 )}
    //                 </AnimatePresence>
    //             </div>
    //         <div className="horizontalSeperator"></div>
    //       </div>
    //     ))}
        
    //   </div>
    // </div>
  // );
};

export default FAQ;
