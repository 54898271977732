// Footer.js
import React from 'react';
import '../css/footer.css';

function Footer() {

  const currentYear = new Date().getFullYear();


  return (
    <footer id="Footer" className=' fade-up'>
      <div className='parent'>

        <div className='div1'>
          <div className='social-links'>
            <a className='instagram' href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank' rel="noreferrer">INSTAGRAM</a>
            <a className='facebook' href="https://www.facebook.com/profile.php?id=61571146656233" target='_blank' rel="noreferrer">FACEBOOK</a>
            <a className='pinterest' href="https://pin.it/7BwfsCyN5" target='_blank' rel="noreferrer">PINTEREST</a>
          </div>
          <div className="rotating-logo">
            <img className="camera" src="/assets/logo-camera.avif" alt="Camera" />
            <img className="text" src="/assets/logo-text.avif" alt="Text" />
          </div>
        </div>

        <div className='div2'>
          <div className='footer-links'>
            <a href="#about">About</a>
            <a href='/portfolio'>Portfolio</a>
            <a href='https://calendly.com/photosbyamna/60min' target='_blank' rel="noreferrer">Book Minis</a>
            <a href='/services'>Info</a>
            {/* <a href="#services">Blog</a> */}
          </div>
        </div>

        <div className='div3'>
          <p className='text-start'>BASED IN TORONTO<br/>AVAILABLE WORLDWIDE</p>
          <div className='footer-heading'>
            <h3>MODERN WEDDING PHOTOGRAPHY<br/>FOR THE NEW ROMANTICS</h3>
          </div>
          <p className='text-end'>GET IN TOUCH<br/>{'PHOTOSBYAMNA@GMAIL.COM'}</p>
        </div>
        
        <div className='copyright'>All rights reserved. @ <span id='copy-year'>{currentYear}</span> Photos by Amna</div>
      </div>
    </footer>
  );
}

export default Footer;
