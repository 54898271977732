import React from "react";
import { useNavigate } from 'react-router-dom';
import "../../css/work.css";

const Work = () => {
  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`/portfolio`, { state: { activeCategory: category } });
  };
  return (
    <section id="portfolio" className="work">
      <h2 className="subtext left heading">&mdash; Our Work</h2>
      <div className="work-content">
      <div className="top-line"></div>

      {/* Weddings Section */}
        <div className="work-item row-reverse fade-up" onClick={() => handleClick('Weddings')}>
          <div className="work-image">
            <img src="/assets/portfolio/weddings.webp" loading="lazy" alt="Toronto Wedding Photographer" name="Toronto Wedding Photographer" />
          </div>
          <div className="work-text">
            <p className="bigNumber">01</p>
            <h2>Weddings & Events</h2>
            <p>This is the best chapter of your book, yet</p>
            <div className="custom-link">Portfolio</div>
            <br/>
          </div>
        </div>
        <p className="subtext right">New beginnings</p>
        {/* Maternity Section */}
        <div className="work-item fade-up" onClick={() => handleClick('Maternity')}>
          <div className="work-image">
            <img src="/assets/portfolio/maternity.webp" loading="lazy" alt="Maternity shoot" name="Toronto Maternity Photographer" />
          </div>
          <div className="work-text">
            <p className="bigNumber">02</p>
            <h2>Maternity</h2>
            <p>This is when life begins and love never ends</p>
            <div className="custom-link">Portfolio</div>
            <br/>
          </div>
        </div>
    
    {/* Engagements Section */}
    <p className="subtext left">Unscripted Love Stories</p>
    <div className="work-item row-reverse  fade-up" onClick={() => handleClick('Engagements')}>
    <div className="work-image">
          <img src="/assets/portfolio/engagements.webp" loading="lazy" alt="Toronto Engagement Photographer" name="Toronto Wedding Photographer" />
        </div>
        <div className="work-text">
          <p className="bigNumber">03</p>
          <h2>Proposals & Engagements</h2>
          <p>These are the moments that speak volumes</p>
          <div className="custom-link">Portfolio</div>
          <br/>
        </div>
      </div>

    {/* Portraits Section */}
    <div className="work-item  fade-up" onClick={() => handleClick('Branding')}>
      <div className="work-image">
          <img src="/assets/portfolio/portraits.webp" loading="lazy" alt="Toronto Photographer Portraits & Family shoot" name="Toronto Family Photographer" />
      </div>
      <div className="work-text">
          <p className="bigNumber">04</p>
          <h2>Portraits & Family</h2>
          <p>This is for life's greatest blessings</p>
          <div className="custom-link">Portfolio</div>
          <br/>
      </div>
    </div>
    <p className="subtext right">Captured Intentionally</p>
    </div>
    </section>
  );
};

export default Work;
