// data-highlightData.js
const highlightData = {
  Weddings: {
    images: [
      "/assets/portfolio-item/Union/4.webp",
      "/assets/portfolio-item/Union/9.webp",
      "/assets/portfolio-item/Union/3.webp",
      "/assets/portfolio-item/Union/16.webp",
      "/assets/portfolio-item/Union/13.webp",
      "/assets/portfolio-item/Union/7.webp",
      "/assets/portfolio-item/WhiteRoses/12.webp",
      "/assets/portfolio-item/WhiteRoses/15.webp",
      "/assets/portfolio-item/WhiteRoses/13.webp",
      "/assets/portfolio-item/WhiteRoses/18.webp",
      "/assets/portfolio-item/TimelessElegance/16.webp",
      "/assets/portfolio-item/TimelessElegance/27.webp",
      "/assets/portfolio-item/TimelessElegance/15.webp",
      "/assets/portfolio-item/TimelessElegance/1.webp",
      "/assets/portfolio-item/TimelessElegance/12.webp",
      "/assets/portfolio-item/TimelessElegance/6.webp",
      "/assets/portfolio-item/TimelessElegance/5.webp",
      "/assets/portfolio-item/TimelessElegance/22.webp",
      "/assets/portfolio-item/OldFilm/9.webp",
      "/assets/portfolio-item/OldFilm/10.webp",
      "/assets/portfolio-item/OldFilm/3.webp",
      "/assets/portfolio-item/OldFilm/4.webp",
      "/assets/portfolio-item/OldFilm/7.webp",
      "/assets/portfolio-item/OldFilm/8.webp",
      "/assets/portfolio-item/OldFilm/5.webp",
      "/assets/portfolio-item/OldFilm/6.webp",
      "/assets/portfolio-item/Seoulmates/1.webp",
      "/assets/portfolio-item/Seoulmates/3.webp",
      "/assets/portfolio-item/Seoulmates/11.webp",
      "/assets/portfolio-item/Seoulmates/7.webp",
      "/assets/portfolio-item/Seoulmates/6.webp",
      "/assets/portfolio-item/Seoulmates/12.webp",
      "/assets/portfolio-item/Seoulmates/2.webp",
      "/assets/portfolio-item/Seoulmates/10.webp",
      "/assets/portfolio-item/PrincessGate/2.webp",
      "/assets/portfolio-item/PrincessGate/10.webp",
      "/assets/portfolio-item/PrincessGate/8.webp",
      "/assets/portfolio-item/PrincessGate/11.webp",     
      "/assets/portfolio-item/FirstDate/9.webp",
      "/assets/portfolio-item/FirstDate/8.webp",
      "/assets/portfolio-item/FirstDate/12.webp",
      "/assets/portfolio-item/FirstDate/11.webp",
      "/assets/portfolio-item/FirstDate/4.webp",
      "/assets/portfolio-item/FirstDate/6.webp",
      "/assets/portfolio-item/CityHallCharm/22.webp",
      "/assets/portfolio-item/CityHallCharm/23.webp",
      "/assets/portfolio-item/CityHallCharm/10.webp",
      "/assets/portfolio-item/CityHallCharm/15.webp",
      "/assets/portfolio-item/CityHallCharm/18.webp",
      "/assets/portfolio-item/CityHallCharm/25.webp",
    ]
  },

    Maternity: {
      images: [
        "/assets/portfolio-item/Hope/2.avif",
        "/assets/portfolio-item/Hope/8.avif",
        "/assets/portfolio-item/Hope/3.avif",
        "/assets/portfolio-item/Hope/4.avif",
        "/assets/portfolio-item/Hope/5.avif",
        "/assets/portfolio-item/Hope/12.avif",
        "/assets/portfolio-item/SandSea/1.webp",
        "/assets/portfolio-item/SandSea/2.webp",
        "/assets/portfolio-item/SandSea/3.webp",
        "/assets/portfolio-item/SandSea/4.webp",
        "/assets/portfolio-item/SandSea/5.webp",
        "/assets/portfolio-item/SandSea/6.webp",
        "/assets/portfolio-item/LilNewYorker/2.webp",
        "/assets/portfolio-item/LilNewYorker/7.webp",
        "/assets/portfolio-item/LilNewYorker/5.webp",
        "/assets/portfolio-item/LilNewYorker/3.webp",
        "/assets/portfolio-item/LilNewYorker/4.webp",
        "/assets/portfolio-item/LilNewYorker/8.webp",
        "/assets/portfolio-item/NewBeginnings/4.webp",
        "/assets/portfolio-item/NewBeginnings/5.webp",
        "/assets/portfolio-item/NewBeginnings/6.webp",
        "/assets/portfolio-item/NewBeginnings/7.webp",
      ]
    },

    Family: {
      images: [
        "/assets/portfolio-item/Union/1.webp",
        "/assets/portfolio-item/Union/2.webp",
        "/assets/portfolio-item/Union/3.webp",
        "/assets/portfolio-item/Union/4.webp",
        "/assets/portfolio-item/Union/5.webp",
        "/assets/portfolio-item/Union/6.webp",
        "/assets/portfolio-item/Union/7.webp",
        "/assets/portfolio-item/Union/8.webp",
        "/assets/portfolio-item/Union/9.webp",
        "/assets/portfolio-item/Union/10.webp",
        "/assets/portfolio-item/Union/11.webp",
        "/assets/portfolio-item/Union/12.webp"
      ]

    },

    Travel: {
      images: [
        "/assets/portfolio-item/Banff/1.webp",
        "/assets/portfolio-item/Banff/2.webp",
        "/assets/portfolio-item/Banff/3.webp",
        "/assets/portfolio-item/Banff/4.webp",
        "/assets/portfolio-item/Banff/5.webp",
        "/assets/portfolio-item/Banff/6.webp",
        "/assets/portfolio-item/Tulum/1.webp",
        "/assets/portfolio-item/Tulum/2.webp",
        "/assets/portfolio-item/Tulum/3.webp",
        "/assets/portfolio-item/Tulum/4.webp",
        "/assets/portfolio-item/Tulum/5.webp",
        "/assets/portfolio-item/Tulum/6.webp",
        "/assets/portfolio-item/Tulum/7.webp",
        "/assets/portfolio-item/Tulum/8.webp",
        "/assets/portfolio-item/Tulum/9.webp",
        "/assets/portfolio-item/Cancun/1.webp",
        "/assets/portfolio-item/Cancun/2.webp",
        "/assets/portfolio-item/Cancun/3.webp",
        "/assets/portfolio-item/Cancun/4.webp",
        "/assets/portfolio-item/Cancun/5.webp",
        "/assets/portfolio-item/Cancun/6.webp",
        "/assets/portfolio-item/Cancun/7.webp",
        "/assets/portfolio-item/Cancun/8.webp",
      ]
    },

    Engagements: {
      images: [
        "/assets/portfolio-item/WhisperedVows/6.webp",
        "/assets/portfolio-item/WhisperedVows/9.webp",
        "/assets/portfolio-item/WhisperedVows/2.webp",
        "/assets/portfolio-item/WhisperedVows/4.webp",
        "/assets/portfolio-item/WhisperedVows/13.webp",
        "/assets/portfolio-item/WhisperedVows/14.webp",
        "/assets/portfolio-item/WhisperedVows/20.webp",
        "/assets/portfolio-item/WhisperedVows/18.webp",
        "/assets/portfolio-item/BlushofOlympus/1.webp",
        "/assets/portfolio-item/BlushofOlympus/2.webp",
        "/assets/portfolio-item/BlushofOlympus/3.webp",
        "/assets/portfolio-item/BlushofOlympus/5.webp",
        "/assets/portfolio-item/ARedKnot/5.webp",
        "/assets/portfolio-item/ARedKnot/6.webp",
        "/assets/portfolio-item/ARedKnot/2.webp",
        "/assets/portfolio-item/ARedKnot/3.webp",
        "/assets/portfolio-item/ARedKnot/13.webp",
        "/assets/portfolio-item/ARedKnot/11.webp",
        "/assets/portfolio-item/SummerGlow/1.webp",
        "/assets/portfolio-item/SummerGlow/2.webp",
        "/assets/portfolio-item/SummerGlow/3.webp",
        "/assets/portfolio-item/SummerGlow/5.webp",
        "/assets/portfolio-item/SummerGlow/7.webp",
        "/assets/portfolio-item/SummerGlow/8.webp",
        "/assets/portfolio-item/diane/1.avif",
        "/assets/portfolio-item/diane/2.avif",
        "/assets/portfolio-item/diane/3.avif",
        "/assets/portfolio-item/diane/4.avif",
        "/assets/portfolio-item/diane/10.avif",
        "/assets/portfolio-item/diane/5.avif",

        "/assets/portfolio-item/diane/8.avif",
        "/assets/portfolio-item/diane/9.avif",
        "/assets/portfolio-item/diane/7.avif",
        "/assets/portfolio-item/diane/6.avif",
      ]
    },

    Branding: {
      images: [
        "/assets/portfolio-item/PostCreativeStudio/1.avif",
        "/assets/portfolio-item/PostCreativeStudio/4.avif",
        "/assets/portfolio-item/PostCreativeStudio/15.avif",
        "/assets/portfolio-item/PostCreativeStudio/9.avif",
        "/assets/portfolio-item/PostCreativeStudio/16.avif",
        "/assets/portfolio-item/PostCreativeStudio/14.avif",
        "/assets/portfolio-item/PostCreativeStudio/3.avif",
        "/assets/portfolio-item/PostCreativeStudio/6.avif",
        "/assets/portfolio-item/BestofJune/2.webp",
        "/assets/portfolio-item/BestofJune/4.webp",
        "/assets/portfolio-item/BestofJune/5.webp",
        "/assets/portfolio-item/BestofJune/1.webp",
        "/assets/portfolio-item/BestofJune/9.webp",
        "/assets/portfolio-item/BestofJune/8.webp",
        "/assets/portfolio-item/Healiness/1.webp",
        "/assets/portfolio-item/Healiness/3.webp",
        "/assets/portfolio-item/Healiness/4.webp",
        "/assets/portfolio-item/Healiness/6.webp",
        "/assets/portfolio-item/Healiness/8.webp",
        "/assets/portfolio-item/Healiness/7.webp",
        "/assets/portfolio-item/Harmonies/4.webp",
        "/assets/portfolio-item/Harmonies/5.webp",
        "/assets/portfolio-item/Harmonies/2.webp",
        "/assets/portfolio-item/Harmonies/7.webp",
      ]
    },

  };
  
  export default highlightData;
  