import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/contact.css';

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formHidden, setFormHidden] = useState(false);
  const today = new Date();
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    date: null, 
    sessionType: '',
    sessionVision: '',
    howDidYouHear: ''
  });

  const [errors, setErrors] = useState({});
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const checkFormComplete = () => {
      const isComplete = Object.values(formValues).every(value => value && value.toString().trim() !== '');
      setIsFormComplete(isComplete);
    };
    checkFormComplete();
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormValues({
      ...formValues,
      date: date
    });
  };

  const validate = () => {
    const errors = {};
    if (!formValues.name) errors.name = '* Name is required';
    if (!formValues.email) errors.email = '* Email is required';
    if (!formValues.date) errors.date = '* Preferred date is required';
    if (!formValues.sessionType) errors.sessionType = '* Session type is required';
    if (!formValues.sessionVision) errors.sessionVision = '* Session vision is required';
    if (!formValues.howDidYouHear) errors.howDidYouHear = '* This field is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
  
    setIsSubmitting(true); // Disable button
    try {
      const token = await executeRecaptcha('submit');
      if (!token) {
        alert('Please complete the CAPTCHA');
        setIsSubmitting(false);
        return;
      }

      setErrors({});
      emailjs.send('service_ttue9te', 'template_t7pf77x', {
        ...formValues,
        date: formValues.date.toISOString().split('T')[0],
        'g-recaptcha-response': token
      }, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log(result.text);
          setFormSubmitted(true);
          setFormHidden(true);  
          
        // ✅ Push event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'contact_form_submit',
          'formName': 'contact_form',
        });



        })
        .catch((error) => {
          console.log(error.text);
          alert('Failed to submit form. Please try again.');
        })
        .finally(() => {
          setIsSubmitting(false); // Re-enable button
        });
    } catch (error) {
      console.error("Error during reCAPTCHA execution:", error);
      alert('Error during CAPTCHA verification. Please try again.');
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <section id="contact">
      <div className="contact-content  fade-up">
      <h2>Let's Connect</h2>
      <p>Use this form to easily get in touch with me, or feel free to email me directly at photosbyamna@gmail.com. I will get back to you as soon as possible!</p>
      {!formHidden && (
      <form id='contact-form' className="contact-form" onSubmit={handleSubmit}>
        {errors.name && <span id="nameError" className="error-message">{errors.name}</span>}
        <label id="nameLabel" htmlFor="name" className="sr-only">Your Name</label>
        <input
        id="name"
          type="text"
          name="name"
          placeholder="Your Name *"
          value={formValues.name}
          onChange={handleChange}
          className="form-input"
          aria-labelledby="nameLabel"
          aria-describedby={errors.name ? "nameError" : undefined}
        />

        {errors.email && <span id="emailError" className="error-message">{errors.email}</span>}
        <label id="emailLabel" htmlFor="email" className="sr-only">Your Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email *"
          value={formValues.email}
          onChange={handleChange}
          className="form-input"
          aria-labelledby="emailLabel"
          aria-describedby={errors.email ? "emailError" : undefined}
        />

        <label id='phoneLabel' htmlFor="phone" className="sr-only">Phone Number</label>
        <input
          id="phone"
          type="number"
          name="phone"
          placeholder="Phone Number (optional)"
          value={formValues.phone}
          onChange={handleChange}
          className="form-input"
          aria-labelledby="phoneLabel"
        />

        {errors.date && <span id="dateError" className="error-message">{errors.date}</span>}
        <label id="dateLabel" htmlFor="date" className="sr-only">Preferred Date</label>
        <DatePicker
          id="date"
          selected={formValues.date}
          onChange={handleDateChange}
          placeholderText="Select a date"
          className="custom-date-picker"
          dateFormat="MMMM d, yyyy"
          minDate={today}
          aria-labelledby="dateLabel"
          aria-describedby={errors.date ? "dateError" : undefined}
        />

        {errors.sessionType && <span id="sessionTypeError" className="error-message">{errors.sessionType}</span>}
        <label id="sessionTypeLabel" htmlFor="sessionType" className="sr-only">Session Type</label>
        <select
          id="sessionType"
          name="sessionType"
          value={formValues.sessionType}
          onChange={handleChange}
          className="form-input dropdown"
          aria-labelledby="sessionTypeLabel"
          aria-describedby={errors.sessionType ? "sessionTypeError" : undefined}
        >
          <option value="">Select session type *</option>
          <option value="Wedding">Wedding or Event</option>
          <option value="Couples">Couples or Engagement</option>
          <option value="Maternity">Maternity</option>
          <option value="Portraits">Portraits</option>
          <option value="Event">Event</option>
          <option value="Branding">Branding</option>
        </select>

        {errors.sessionVision && <span id="sessionVisionError" className="error-message">{errors.sessionVision}</span>}
        <label id="sessionVisionLabel" htmlFor="sessionVision" className="sr-only">Session Vision</label>
        <textarea
          id="sessionVision"
          name="sessionVision"
          placeholder="What do you envision for your session? (Please share venue, times and any other details) *"
          value={formValues.sessionVision}
          onChange={handleChange}
          className="form-textarea"
          aria-labelledby="sessionVisionLabel"
          aria-describedby={errors.sessionVision ? "sessionVisionError" : undefined}
        />
        {errors.howDidYouHear && <span id="howDidYouHearError" className="error-message">{errors.howDidYouHear}</span>}
        <label id="howDidYouHearLabel" htmlFor="howDidYouHear" className="sr-only">How Did You Hear</label>
          <select
            id="howDidYouHear"
            name="howDidYouHear"
            value={formValues.howDidYouHear}
            onChange={handleChange}
            className="form-input dropdown"
            aria-labelledby="howDidYouHearLabel"
            aria-describedby={errors.howDidYouHear ? "howDidYouHearError" : undefined}
          >
            <option value="">How did you hear about me? *</option>
            <option value="Google/Search Engine">Google/Search Engine</option>
            <option value="Instagram">Instagram</option>
            <option value="Facebook">Facebook</option>
            <option value="Bark">Bark</option>
            <option value="Referral">Referral</option>
            <option value="Pinterest">Pinterest</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Other">Other</option>
          </select>

          <button
            type="submit" 
            className={`form-button ${isFormComplete ? 'form-button-complete' : ''}`} 
            disabled={isSubmitting}>
            {isSubmitting ? 'Sending' : 'Submit'}
          </button>

      </form>
      )}
      {formSubmitted && (
        <div className="success-message">
          <img className='checkmark-icon' src="./assets/checkmark.svg" alt="Success" />
          <p>Thank you for reaching out! 🎉<br/>Your inquiry has been received, and you should get a confirmation email shortly. I will be in touch as soon as possible to chat more about your session!</p>
        </div>
      )}
      </div>
    </section>
  );
};

export default Contact;
