import React, { useEffect, useState, useRef } from 'react';
import '../../css/testimonials.css';

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const carouselRef = useRef(null);

  const images = [
    { photo: '/assets/testimonials/zakia-review.avif', title: 'Zakia & Erhan', description: `"She captured genuine emotions and created a relaxed atmosphere during the shoot, resulting in stunning photos that exceeded my expectations."` },
    { photo: '/assets/testimonials/ahmet-review.avif', title: 'Yazel & Ahmet', description: `"Our photos turned out so dreamy. We loved every picture and she was so accommodating and helpful."` },
    { photo: '/assets/testimonials/fion-review.avif', title: 'Fion & Michael', description: `"She was very personable and professional, and helped us with poses as we are quite camera-shy."` },
    { photo: '/assets/testimonials/alysia-review.avif', title: 'Alysia & Aleem', description: `"She understood my vision, and captured everything my partner and I wanted to the T!"` },
    { photo: '/assets/testimonials/tanaz-review.avif', title: 'Tanaz Bhathena', description: `"She was prompt and professional, going above and beyond to get good photos of me. At the end, I had a TON of options to choose from!"` },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(nextSlide, 15000);
    return () => clearInterval(interval);
  }, []);

  // Touch events for swipe support
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const swipeThreshold = 50; // Minimum swipe distance
    if (touchStart - touchEnd > swipeThreshold) {
      nextSlide(); // Swipe left → Next slide
    }
    if (touchEnd - touchStart > swipeThreshold) {
      prevSlide(); // Swipe right → Previous slide
    }

    // Reset values
    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <>
    
      <h2 className="subtext heading left testimonial">&mdash; Kind Words</h2>
      <section id="testimonials">
        
        <h3>What Clients are saying</h3>
        <div
          className="carousel"
          ref={carouselRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="carousel-wrapper"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {images.map((image, index) => (
              <div key={index} className="carousel-slide">
                <img
                  src={image.photo}
                  alt={image.title}
                  className="carousel-image testimonial-photo"
                  loading="lazy"
                />
                <div className="carousel-image testimonial-text">
                  <h4>{image.title}</h4>
                  <img src='/assets/stars.svg' alt="Stars" />
                  <p>{image.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='next-btn'>
          <div className="arrow right" onClick={nextSlide}><img src="/assets/arrow-black.svg" alt="Next" /></div>
        </div>
        </div>

       
      </section>
      
    <section className='badge'>
      
      <h3>Named one of the top 21 Wedding Photographers in Toronto 2025</h3>
      <div style={{ textAlign: "center", padding: "1rem" }}>
      <a 
        href="https://www.bestforbride.com/bridal-shop/15/top-wedding-photographers-in-toronto-and-gta/" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ display: "inline-block" }}
      >
        <img 
          src="/assets/badge.avif" 
          alt="Award Badge" 
          style={{ maxWidth: "200px", height: "auto" }}
        />
      </a>
    </div>
    </section>
    </>
  );
};

export default Testimonials;



