export const images = {
  Featured: [
    {
      src: "/assets/portfolio/featured/9.webp",
      title: "Timeless Elegance",
      name: "Larissa and Ben",
      date: "31 . 08 . 2024",
      description: "An intimate ceremony, held in the historic room of St. George’s Golf & Country Club, was a truly magical experience. Surrounded by their closest loved ones, I captured the timeless moment as they exchanged their heartfelt vows."
    },
    {
      src: "./assets/portfolio/featured/1.webp",
      title: "White Roses",
      name: "Yazel and Ahmed",
      date: "28 . 04 . 2024",
      description: "The newlyweds sharing cherished moments right after their Nikkah ceremony."
    },

    {
      src: "./assets/portfolio/featured/2.webp",
      title: "Sand & Sea",
      name: "Sam and Liam",
      date: "12 . 04 . 2024",
      description: "A joyful photoshoot at Scarborough Bluffs announcing the arrival of their first baby."
    },

    {
      src: "./assets/portfolio/featured/4.webp",
      title: "Castles & Dreams",
      name: "Mandeep and Manpreet",
      date: "5 . 08 . 2024",
      description: "A romantic prewedding shoot at Casa Loma."
    },
    {
      src: "./assets/portfolio/featured/5.webp",
      title: "Lil New Yorker",
      name: "Cardine and Paul",
      date: "12 . 05 . 2024",
      description: "A maternity shoot in a hotel room Downtown Toronto blending urban energy."
    },
    {
      src: "./assets/portfolio/featured/6.webp",
      title: "A Red Knot",
      name: "Sejun and Lucy",
      date: "24 . 06 . 2024",
      description: "A surprise proposal at Scarborough Bluffs followed by a fun couple's shoot."
    }
  ],
  Weddings: [
    {
      src: "/assets/portfolio-item/TimelessElegance/18.webp",
      title: "Timeless Elegance",
      name: "Larissa and Ben",
      date: "31 . 08 . 2024",
      description: "An intimate ceremony, held in the historic room of St. George’s Golf & Country Club, was a truly magical experience. Surrounded by their closest loved ones, I captured the timeless moment as they exchanged their heartfelt vows."
    },
    {
      src: "/assets/portfolio-item/Union/3.webp",
      title: "Union",
      name: "Alysia and Aleem",
      date: "18 . 02 . 2024",
      description: "A prewedding shoot set in the heart of downtown Toronto Union Station."
    },
    {
      src: "/assets/portfolio-item/CityHallCharm/22.webp",
      title: "City Hall Charm",
      name: "Daniela & Daniel",
      date: "24 . 06 . 2024",
      description: "A City Hall Wedding surrounded by laughter, bouquets and lots of hugs."
    },
    {
      src: "/assets/portfolio-item/Seoulmates/7.webp",
      title: "Seoulmates",
      name: "Michael and Fionn",
      date: "24 . 05 . 2023",
      description: "An intimate exchange of vows in the Toronto City Hall."
    },
    {
      src: "/assets/portfolio-item/OldFilm/9.webp",
      title: "Old Film",
      name: "Zakia and Erhan",
      date: "10 . 05 . 2024",
      description: "A film-like elopement shoot in University of Toronto."
    },
    {
      src: "/assets/portfolio-item/WhiteRoses/15.webp",
      title: "White Roses",
      name: "Yazel and Ahmed",
      date: "28 . 04 . 2024",
      description: "The bride and groom's nikkah ceremony and couple's shoot in Hamilton."
    },
    {
      src: "/assets/portfolio-item/FirstDate/4.webp",
      title: "First Date",
      name: "Meher and Ranveet",
      date: "24 . 06 . 2024",
      description: "Celebrating the beautiful wedding that followed after their perfect date."
    },
    {
      src: "/assets/portfolio-item/PrincessGate/2.webp",
      title: "Princess Gate",
      name: "Samantha and Matthew",
      date: "31 . 08 . 2024",
      description: "A lovely couples shoot on a chilly, fog-filled spring evening at the Princes' Gates."
    },
  ],
  Engagements: [
    {
      src: "/assets/portfolio-item/ARedKnot/5.webp",
      title: "A Red Knot",
      name: "Sejun and Lucy",
      date: "24 . 06 . 2024",
      description: "A surprise proposal at Scarborough Bluffs followed by a fun couple's shoot."
   },
    {
      src: "/assets/portfolio-item/CastlesandDreams/6.webp",
      title: "Castles & Dreams",
      name: "Mandeep and Manpreet",
      date: "5 . 08 . 2024",
      description: "A romantic prewedding shoot at Casa Loma." 
    },
    {
      src: "/assets/portfolio-item/WhisperedVows/10.webp",
      title: "Whispered Vows",
      name: "Faye and Alex",
      date: "24 . 08 . 2024",
      description: "An intimate proposal in a secluded spot in Trillium park."
    },
    {
      src: "/assets/portfolio-item/SummerGlow/8.webp",
      title: "Summer Glow",
      name: "Amy and Kevin",
      date: "24 . 08 . 2024",
      description: "Shooting the cover for a heartfelt love song at the Royal Botanical Gardens"
    },
    {
      src: "/assets/portfolio-item/BlushofOlympus/4.webp",
      title: "Blush of Olympus",
      name: "Shawana and Awad",
      date: "12 . 05 . 2024",
      description: "Couples shoot in the gardens of Guild Inn Estate"
    }
  ],
  Maternity: [
    {
      src: "/assets/portfolio-item/Hope/8.avif",
      title: "Hope",
      name: "Sanniah & Hassan",
      date: "23 . 09 . 2024",
      description: "Awaiting Their Little One - A maternity session at the University of Toronto, honoring their journey from students to soon-to-be parents."
    },
    {
      src: "/assets/portfolio-item/LilNewYorker/5.webp",
      title: "Lil New Yorker",
      name: "Cardine and Paul",
      date: "12 . 05 . 2024",
      description: "A maternity shoot blending urban energy"
    },
    {
      src: "/assets/portfolio-item/SandSea/9.webp",
      title: "Sand & Sea",
      name: "Sam and Liam",
      date: "12 . 04 . 2024",
      description: "A joyful photoshoot at Scarborough Bluffs Park announcing the arrival of their first baby."
    },
    {
      src: "/assets/portfolio-item/NewBeginnings/9.webp",
      title: "New Beginnings",
      name: "Amna and Asad",
      date: "15 . 01 . 2024",
      description: "A maternity shoot at Old Mills, celebrating the anticipation and joy of welcoming their firstborn."
    }
  ],
  Branding: [
    {
      src: "/assets/portfolio-item/PostCreativeStudio/4.avif",
      title: "Post Creative Studio",
      date: "08 . 02 . 2025",
      description: "A branding session at Post Creative Studio Toronto."
    },
    {
      src: "/assets/portfolio-item/BestofJune/4.webp",
      title: "Best of June",
      date: "20 . 07 . 2024",
      description: "Portrait session at a birdhouse capturing the author’s creativity and essence."
    },
    {
      src: "/assets/portfolio-item/Healiness/3.webp",
      title: "Healiness",
      date: "20 . 05 . 2024",
      description: "At-home and studio branding portrait shoot showcasing the heart and personality of the business owners."
    },
    {
      src: "/assets/portfolio-item/Harmonies/8.webp",
      date: "24 . 08 . 2024",
      title: "Harmonies",
      description: "Shooting a Spotify cover for a heartfelt love song in the Royal Botanical Gardens"
    }
  ],
  Travel: [
    {
      src: "/assets/portfolio-item/Tulum/2.webp",
      title: "Tulum",
      description: "Street photography on a tourist busy Tulum."
    },
    {
      src: "/assets/portfolio-item/Cancun/2.webp",
      title: "Cancun",
      description: "A vibrant travel shoot in Cancun"
    },
    {
      src: "/assets/portfolio-item/Banff/3.webp",
      title: "Banff",
      description: "A spring visit to Banff and some nature photography."
    }
  ]
};