import React, { useState, useRef, useEffect } from 'react';
import "../../css/portfolio.css";
import highlightData from './data/highlight-data';
import { images } from "./data/portfolio-item-data";
import { hideAllItems, showItemsByCategory } from '../../utils/helpers';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet-async";

const Portfolio = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(location.state?.activeCategory || 'Weddings');
  const [highlightMode, setHighlightMode] = useState(true);

  const filterRef = useRef(null);
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const handleToggle = (mode) => {
    setHighlightMode(mode === 'highlight-mode');
  };

  const handleCategoryClick = (name) => {
    setActiveCategory(name);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!highlightMode) {
      hideAllItems();
      showItemsByCategory(activeCategory);
    }
    if (filterRef.current) {
      const activeElement = filterRef.current.querySelector(`.filter-btn.active`);
      if (activeElement) {
        const { offsetLeft, offsetWidth } = activeElement;
        setBackgroundStyle({
          width: `${offsetWidth}px`,
          transform: `translateX(${offsetLeft}px)`
        });
      }
    }
  }, [activeCategory, highlightMode]);

  const categories = [
    { name: 'Weddings', label: 'Weddings' },
    { name: 'Engagements', label: 'Engagements' },
    { name: 'Maternity', label: 'Maternity' },
    { name: 'Branding', label: 'Branding' },
    { name: 'Travel', label: 'Travel' }
  ];

  return (
    <section id='portfolio-page'>
    <Helmet>
      <title>Portfolio | Photos by Amna</title>
      <meta name="description" content="Check out my latest wedding and portrait photography." />
      <meta name="keywords" content="photography, wedding photography, portrait photography" />
      <meta name="author" content="Photos by Amna" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Portfolio | Photos by Amna" />
      <meta property="og:description" content="A collection of my best photography work." />
      <meta property="og:image" content="https://www.photosbyamna.com/ogimage.jpg" />
      <meta property="og:url" content="https://photosbyamna.com/portfolio" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://photosbyamna.com/portfolio" />
    </Helmet>
      <div className='top-container'>
        <h1 className='bigNumber'>PORTFOLIO</h1>

        <div id="filter" ref={filterRef}>
          <div
            className="filter-background"
            style={backgroundStyle}
          />
          {categories.map(category => (
            <div
              key={category.name}
              className={`filter-btn ${activeCategory === category.name ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.label}
            </div>
          ))}
        </div>


        {/* Dropdown for mobile */}
        <div className="filter-dropdown">
          <button className="filter-dropdown-btn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {activeCategory}
          </button>
          {isDropdownOpen && (
            <div className="filter-dropdown-menu">
              {categories.map(category => (
                <div
                  key={category.name}
                  className={`filter-dropdown-item ${activeCategory === category.name ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  {category.label}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Highlight / Gallery Toggle */}
        <div className="toggle-container">
          <span className={`toggle-option ${highlightMode ? "active" : ""}`} onClick={() => handleToggle('highlight-mode')}>Highlights</span>
          <div
            className={`toggle-switch ${highlightMode ? "highlight-mode" : "gallery-mode"}`}>
            <div className="toggle-indicator"></div>
          </div>
          <span className={`toggle-option ${highlightMode ? "" : "active"}`} onClick={() => handleToggle('gallery-mode')}>Galleries</span>
        </div>
      </div>

      <div className='bottom-container'>
        {/* Highlight Mode */}
        {highlightMode ? (
          <motion.div
            className={`highlight-container ${highlightMode ? "fade-in" : "fade-out"}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Highlight images */}
            <div className="highlights-grid">
              {highlightData[activeCategory]?.images.map((src, index) => (
                <div><img
                  key={index}
                  src={src}
                  name="Toronto Photographer"
                  alt={`Toronto Wedding Photographer ${index + 1}`}
                  style={{ '--index': index }}
                  loading="lazy" 
                /></div>
              ))}
            </div>
          </motion.div>
        ) : (
          <motion.div
            id="gallery"
            className={`gallery ${!highlightMode ? "fade-in" : "fade-out"}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Gallery items */}
            {images[activeCategory]?.map(({ src, title, name, date, description }, index) => (
              <div key={index} className={`item ${activeCategory}`}
                onClick={() => navigate(`/portfolio/${title.replace(/\s+/g, "").replace(/&/g, "and")}`)}>
                <div className="photo">
                  <img src={src} alt={`Toronto Wedding Photographer ${index + 1}`} name="Toronto Photographer" loading="lazy" />
                </div>
                <div className="details">
                  <div>
                    <h1>0{index + 1}</h1>
                    <p style={{ fontWeight: "600" }}>{title}</p>
                    {name && <p>{name}</p>}
                  </div>
                  <div>
                    {date && <p style={{ fontWeight: "600" }}>{date}</p>}
                    <p>{description}</p>
                  </div>
                  <div className="accent-btn">
                    <span className="btn-circle"></span>
                    <span className="btn-text">See Collection</span>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
