import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import portfolioData from './data/portfolio-data'; // Import the data
import "../../css/portfolio-item.css";
import { Helmet } from "react-helmet-async";

const PortfolioItem = () => {
  const { title } = useParams();
  const navigate = useNavigate();
  const [galleryKey, setGalleryKey] = useState(Date.now()); // State to trigger re-render

  const itemData = portfolioData[title];

  // Get the list of titles from portfolioData
  const titles = Object.keys(portfolioData);

  // Find the current index of the title
  const currentIndex = titles.indexOf(title);

  // Scroll to the top when the component is mounted or title changes
  useEffect(() => {
    window.scrollTo(0, 0);
    setGalleryKey(Date.now()); // Trigger re-render
  }, [title]);

  

  // Navigation handlers
  const handlePrevious = () => {
    const previousIndex = (currentIndex - 1 + titles.length) % titles.length;
    const previousTitle = titles[previousIndex];
    navigate(`/portfolio/${previousTitle}`);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % titles.length;
    const nextTitle = titles[nextIndex];
    navigate(`/portfolio/${nextTitle}`);
  };

  if (!itemData) {
    return <div>Item not found</div>;
  }

  const pageTitle = `Unscripted Love Stories | Photos by Amna`;
  const pageDescription = itemData.description[0] || "Celebrate your love with emotive, natural couples photography. From engagements to anniversaries, capture moments that reflect your connection.";
  const pageURL = `https://photosbyamna.com/portfolio/${title}`;
  const pageImage = itemData.images[0] || "https://www.photosbyamna.com/ogimage.jpg";

  return (
    <div className="portfolio-item">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={pageURL} />

        {/* Open Graph (Facebook) */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={pageURL} />
        <meta property="og:image" content={pageImage} />
      </Helmet>
      <div className='portfolio-banner'>
        <h2 style={{textTransform:'none'}}>{itemData.title}</h2>
        {itemData.description.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>

      {/* Navigation buttons */}
      <div className="navigation-buttons">
        <div className="arrow left" onClick={handlePrevious}><img src="/assets/arrow-black.svg" alt="Previous"/></div>
        <div className="arrow right" onClick={handleNext}><img src="/assets/arrow-black.svg" alt="Next"/></div>
      </div>

      <div className="logo">
        <img className="camera" src="/assets/logo-camera-black.png" alt="Camera" />
        <img className="text" src="/assets/logo-text-black.png" alt="Text" />
      </div>

      <div className="image-gallery  fade-up" key={galleryKey}>
        {itemData.images.map((src, index) => (
          <img
            key={index}
            src={src}
            name="Toronto Photographer"
            alt={`Toronto Photographer ${itemData.title} ${index + 1}`}
          />
        ))}
      </div>

    </div>
  );
};

export default PortfolioItem;
