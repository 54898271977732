import React, { useEffect, useRef,useState } from 'react';
// import { gsap } from 'gsap';
import {ScrollTrigger } from "../../utils/gsapConfig";

import '../../css/about.css';

const About = () => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
  const readMoreRef = useRef(null);

  const profilePhotoRef = useRef(null);
  const rightGridRef = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById('aboutSection');
      const scrollValue = window.scrollY - aboutSection.offsetTop;

      if (aboutSection) {
        const sectionTop = aboutSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight && sectionTop > -aboutSection.offsetHeight) {
          const parallaxValue = scrollValue * 0.08; // Adjust parallax strength

          if (profilePhotoRef.current) {
            profilePhotoRef.current.style.transform = `translateY(${-parallaxValue}px)`;
          }
          if (rightGridRef.current) {
            rightGridRef.current.style.transform = `translateY(${parallaxValue}px)`;
          }
        }
      }
    };

    if(window.innerWidth > 768){
     window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // useEffect(() => {

  //   ScrollTrigger.create({
  //     id:'about',
  //     trigger: '#aboutSection',
  //     start: 'top center',
  //     end: 'bottom center',
  //     onEnter: () => gsap.to(document.body, { backgroundColor: '#1e1e1c', duration: 0.5 }),
  //     onEnterBack: () => gsap.to(document.body, { backgroundColor: '#1e1e1c', duration: 0.5 }),
  //     onLeave: () => gsap.to(document.body, { backgroundColor: '#F8F5F2', duration: 0.5 }),
  //     onLeaveBack: () => gsap.to(document.body, { backgroundColor: '#F8F5F2', duration: 0.5 }),
  //   });

  //   return () => {
  //     ScrollTrigger.getById("about")?.kill();
  //   };
  // }, []);

  useEffect(() => {
    if (readMoreRef.current) {
      if (isReadMoreVisible) {
        readMoreRef.current.style.height = `${readMoreRef.current.scrollHeight}px`;
      } else {
        readMoreRef.current.style.height = '0px';
      }
    }
  }, [isReadMoreVisible]);

  // const toggleReadMore = () => {
  //   setIsReadMoreVisible(!isReadMoreVisible);
  //   console.log(isReadMoreVisible);
  // };
  const toggleReadMore = () => {
    setIsReadMoreVisible(prevState => {
      const newState = !prevState;
      

      // setTimeout(() => {
      //   ScrollTrigger.refresh();
      // }, 200); 
      
      return newState;
    });
  };
  

  return (
    <section id="aboutSection">
      <div className="about-content" id='about-content'>
        <div className="profile-photo" ref={profilePhotoRef}>
          <img alt="profile-photo" src="./assets/profile-photo2.avif" />
        </div>
        <div className="right-grid" ref={rightGridRef}>
          {/* <p className='cursive'>{isReadMoreVisible ? `hello, i'm amna` : 'The photographer behind the lens,'}</p> */}
          
          <p className='cursive'>{isReadMoreVisible ? `Amnaazhar` : 'The photographer behind the lens'}</p>
          <p>My approach is rooted in emotion—capturing authenticity in a way that feels deeply personal.
          </p>
          {/* <div className='horizontalSeperator'></div> */}
          <p>
            When you book me, you're getting more than a session—you’re investing in a seamless, stress-free experience. From consultation to final gallery, I personally handle all details so you can focus on the moment. Whether it's a wedding, maternity, or couple’s session, I create a comfortable space for natural, authentic moments.
          </p>
          <p className={`readmore ${isReadMoreVisible ? 'visible' : 'hidden'}`}
            ref={readMoreRef}>
            When I’m not behind the camera, you’ll find me obsessing over camera gear, studying cinematography in films, mapping out my next adventure, or experimenting in the kitchen—sometimes with great results.
            I also love to draw for fun—my doodles lives over at
            <a className='link-on-dark' href='https://www.instagram.com/galwhodraws/' target='_blank'> @galwhodraws</a>
            </p>
            
          <div className="unstyled-link dark" onClick={toggleReadMore} style={{fontSize:"1.5rem"}}>{isReadMoreVisible ? '◡̈' : '♡'}</div>
          
          {/* <div className="btn-container">
            <a className="accent-btn" href='/about'>
              <span className="btn-circle"></span>
              <span className="btn-text">Go Behind The Scenes</span>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
