import React from "react";
import { useNavigate } from 'react-router-dom';
import "../../css/services-section.css";
import { Link } from 'react-scroll';


const ServicesSection = () => {

  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate('/services', { state: { activeCategory: category } });
  };

  return (
    
    <div className="container">
            <div style={{position: 'relative'}}>
      <h2 className="subtext heading left services">&mdash; Our Services</h2>
      </div>
      <section id="sectionPin">
        <div className="pin-wrap">
          <div>
            <h2>
              These are your “once-in-a-lifetime” moments
            </h2>
            <img src="/assets/portfolio-item/OldFilm/9.webp" alt="Toronto Wedding Photographer" name="Toronto Wedding Photographer" loading="lazy"/>
          </div>
          <div>
            <img src="/assets/portfolio-item/OldFilm/3.webp" alt="Toronto Wedding Photographer"  name="Toronto Wedding Photographer" loading="lazy"/>
            <p style={{textAlign: "start"}}>
              Planning a wedding or any special milestone can feel overwhelming — I've been there! 
              But one thing I've learned is that photos are one of the most meaningful investments you can make. 
              Whether it's your big day, an intimate celebration, or a moment worth cherishing, 
              your photos will serve as lasting memories for generations!
            </p>
          </div>
          <div>
          <h2>
              Your Raw Unscripted Love, Told the way you You Live It.
            </h2>
            <img src="/assets/portfolio-item/TimelessElegance/6.webp" alt="Toronto Wedding Photographer" name="Toronto Wedding Photographer" loading="lazy"/>

          </div>
          <div>
          <img src="/assets/portfolio-item/TimelessElegance/15.webp" alt="Toronto Wedding Photographer" name="Toronto Wedding Photographer" loading="lazy"/>

          <p>
            Your love story is unique and deserves to be documented with honesty and elegance.  
            Through a blend of candid moments and timeless artistry, I create photographs  
            that let you relive every heartfelt emotion, over and over again.</p>            
          </div>
        </div>
      </section>



      
      {/* Services Section */}


      <section id="services" className="aboutServices fade-up">
        <div className="left-col">
          <h2 className="services-title">
            <span className="title-default">Dreamy, Timeless & Authentic Photos</span>
            <span className="title-hover">Serving Toronto, GTA & the World</span>
          </h2>
        </div>

        <div className="right-col">
          <div className="service" onClick={() => handleClick('Weddings')}>
            <div className="service-header">
              <h3>Weddings</h3>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <h4 className="hover-text">See Pricing</h4>
                <div className="download-arrow"></div>
              </div>
              
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
              <div>
                <p>
                  2-10 hours of photo & video with drone coverage<br />
                  Unlimited digital images<br/>
                  30-60 min engagement shoot
                </p>
              </div>
              <div>
                <p>
                  Second Professional Photographer<br />
                  Personalized timeline assistance<br />
                  Full gallery within 3 weeks
                </p>
              </div>
            </div>
          </div>


          <div className="service"  onClick={() => handleClick('Couples')}>
            <div className="service-header">
              <h3>Couples</h3>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <h4 className="hover-text">See Pricing</h4>
                <div className="download-arrow"></div>
              </div>
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
            <p>
              For engagements, proposals, elopements, maternity, or just because. Each session includes 60 minutes of shooting, unlimited digital photos, a what to wear guide if interested, printing rights, and full gallery within 1 week.
            </p>
            </div>
          </div>

          <div className="service"  onClick={() => handleClick('Others')}>
            <div className="service-header">
              <h3>Others</h3>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <h4 className="hover-text">See Pricing</h4>
                <div className="download-arrow"></div>
              </div>
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
            <p>
              For portraits, family, branding, or lifestyle, each session includes 60+ minutes of shooting, unlimited digital photos, printing rights, and full gallery within 1 week.
            </p>
            </div>
          </div>
          <Link to="contact" className='custom-link' smooth={true} duration={1000}>Inquire for your Customised Quote</Link>
        </div>
      </section>

    </div>
  );
};

export default ServicesSection;
