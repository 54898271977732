import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

const Pricing = () => {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(location.state?.activeCategory || 'Weddings');
  const filterRef = useRef(null);
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const handleCategoryClick = (name) => {
    setActiveCategory(name);
  };

  useEffect(() => {
    if (filterRef.current) {
      const activeElement = filterRef.current.querySelector(`.filter-btn.active`);
      if (activeElement) {
        const { offsetLeft, offsetWidth } = activeElement;
        setBackgroundStyle({
          width: `${offsetWidth}px`,
          transform: `translateX(${offsetLeft}px)`,
        });
      }
    }
  }, [activeCategory]);

  const categories = [
    { name: "Weddings", label: "Weddings" },
    { name: "Couples", label: "Couples" },
    { name: "Others", label: "Others" },
  ];

  const pricingData = {
    Weddings: {
      title: "Weddings",
      packages: [
        {
          name: "Full Day ( $ 3.5K - 4K )",
          description:
            "8-10 hours of photo & video coverage, including a complete photo album (Getting Ready, Bridal Party, Couple & Family, Ceremony, Reception & Dinner). Includes retouching on request, a 30-60 min engagement shoot, drone footage, and both highlight and documentary-style videos.",
        },
        {
          name: "Half Day ( $ 1K - 3K )",
          description:
            "4-6 hours of photo and/or video coverage, ideal for mid-size celebrations. Includes an unlimited edited photo album, retouching on request, and both highlight and documentary-style videos.",
        },
        {
          name: "Intimate ( $ 550 )",
          description:
            "2 hours of photo coverage, perfect for city hall weddings, engagements, or elopements. Includes an unlimited edited photo album, coverage of the ceremony, family & couple photos, and retouching on request. Add a highlight video with drone footage for $ 200.",
        },
        
      ],
      images: [
        "/assets/portfolio-item/OldFilm/2.webp",
        "/assets/portfolio-item/TimelessElegance/9.webp",
        "/assets/portfolio-item/TimelessElegance/6.webp",
        "/assets/portfolio-item/Union/1.webp",
        "/assets/portfolio-item/TimelessElegance/15.webp",
        "/assets/portfolio-item/FirstDate/9.webp",
      ],
    },
    Couples: {
      title: "Couples",
      packages: [
        {
          name: "Couples Shoot ( $ 340 )",
          description:
            "1-1.5 hour of photo coverage perfect for proposals, maternity or anniversaries\nUnlimited Photo Album\nLocation of your choice\nA what-to-wear and planning guide upon request\nA highlight video for additional $ 150",
        },
        {
          name: "Pre-Wedding ( $ 1K - 1.5K )",
          description:
            "3-4 hours of photo & video coverage\n30+ edited images photo album\nMultiple locations & outfit changes*\nDrone footage\nA what-to-wear and planning guide upon request\nA 3-4min cinematic video edited with music of choice.",
        },
      ],
      images: [

        "/assets/portfolio-item/WhisperedVows/14.webp",
        "/assets/portfolio-item/WhisperedVows/4.webp",
        "/assets/portfolio-item/SummerGlow/2.webp",
        "/assets/portfolio-item/SandSea/12.webp",
        "/assets/portfolio-item/LilNewYorker/2.webp",
        
        "/assets/portfolio-item/NewBeginnings/7.webp",
      ],
    },
    Others: {
      title: "Others",
      packages: [
        {
          name: "A la Carte ( $ 340 / hr )",
          description:
            "Customized packages for portraits, branding, and events\nIncludes high-resolution digital images.",
        },
        {
          name: "Mini Session ( $ 200 )",
          description:
            "Perfect for intimate & significant celebrations like birthdays, holidays, socials or gifting, this 30-minute mini session captures it perfectly.",
        },
      ],
      images: [
        "/assets/portfolio-item/Healiness/1.webp",
        "/assets/portfolio-item/Healiness/6.webp",
        "/assets/portfolio-item/Harmonies/8.webp",
        "/assets/portfolio-item/BestofJune/2.webp",
        "/assets/portfolio-item/Harmonies/5.webp",
        "/assets/portfolio-item/BestofJune/1.webp",
      ],
    },
  };

  return (
    <div className="pricing-section fade-up" id="pricing">
      <div className="top-container">
        <h2>Investment</h2>
        <div id="filter" ref={filterRef} className="filter-container light">
          <div className="filter-background" style={backgroundStyle} />
          {categories.map((category) => (
            <div
              key={category.name}
              className={`filter-btn ${activeCategory === category.name ? "active" : ""}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.label}
            </div>
          ))}
        </div>
      </div>

      <div className="pricing-content">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="grid-row-container"
          >
            <div className="package-container">
              {/* <h3>{pricingData[activeCategory].title}</h3> */}
              {pricingData[activeCategory].packages.map((pkg, index) => (
                <div key={index} className="package-item">
                  <h4>{pkg.name}</h4>
                  <p>
                    {pkg.description.split("\n").map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              ))}
            </div>
            <div className="image-grid">
              {pricingData[activeCategory].images.map((img, index) => (
                <img
                  className="grid-image"
                  key={index}
                  src={img}
                  name="Toronto Wedding Photographer Pricing"
                  alt="Toronto Wedding Photographer Pricing"
                  loading="lazy"
                />
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <p>Pricing is inclusive of all costs including travel fees within GTA Toronto.</p>
      <p>Every shoot is one of a kind, and my goal is to provide you with a seamless, stress-free experience. A la Carte options are available for all packages, and the best way to proceed is by getting a personalized quote tailored to your needs.</p>
      <a className="custom-link" href="/services#contact">Inquire Now for your customized quote</a>
    </div>
  );
};

export default Pricing;
