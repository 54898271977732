import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Home/Hero';
import Work from './components/Home/Work';
import Intro from './components/Home/Intro';
import About from './components/Home/About';
import AboutMe from './components/Pages/About-Me';
import Contact from './components/Contact';
import ServicesSection from './components/Home/ServicesSection';
import Services from './components/Pages/Services';
import Footer from './components/Footer';
import Testimonials from './components/Home/Testimonials';
import Portfolio from './components/Pages/Portfolio';
import PortfolioItem from './components/Pages/PortfolioItem';
import NotFound from './components/NotFound';
import BlogPage from './components/Pages/BlogPage';
import BlogTemplate from './components/Pages/BlogTemplate';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from "react-helmet-async";
import './css/App.css';


function AppContent() {
  const [isHeroLoaded, setIsHeroLoaded] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  useEffect(() => {
    if (isHomePage) {
      setTimeout(() => {
        setIsHeroLoaded(true);
        document.body.style.overflowY = 'auto';
      }, 300); /* say 1500 for effect */
    } else {
      setIsHeroLoaded(true);
      document.body.style.overflowY = 'auto';
    }
  }, [isHomePage]);


  // useEffect(() => {
  //   const elements = document.querySelectorAll(".fade-up");

  //   const observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add("visible");
  //           observer.unobserve(entry.target); // Stop observing once visible
  //         }
  //       });
  //     },
  //     {threshold: 0.2 }
  //   );

  //   elements.forEach((el) => observer.observe(el));

  //   return () => observer.disconnect();
  // }, []);


  return (
    <div className={`App ${isHeroLoaded ? 'loaded' : ''}`}>
      {/* {isHomePage ? <Header /> : <AltHeader />} */}
      <Header />
      
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero isHeroLoaded={isHeroLoaded} />
              <Intro />
              <Work />
              <About />
              <ServicesSection />
              <Testimonials />
            </>
          }
        />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:title" element={<PortfolioItem />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutMe/>} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogTemplate />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Contact />
      <Footer />
    </div>
  );
}

function App() {

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <Router>
          <AppContent />
        </Router>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
}

export default App;
