import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ScrollToSectionLink from '../utils/ScrollToSectionLink';
import '../css/header.css';


function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [pastHeaderSection, setpastHeaderSection] = useState(false);
  const [isHeaderAtTop, setIsHeaderAtTop] = useState(false);
  // const [sideNavDropdown, setSideNavDropdown] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if(!menuOpen){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflowY = 'auto';
    }
  };
  
  const handlePortfolioClick = (category) => {
    toggleMenu();
    navigate(`/portfolio`, { state: { activeCategory: category } });
    window.scrollTo(0, 0);
    window.location.reload();
    
  };
  
  useEffect(() => {
    if (window.innerWidth > 768 || (window.innerWidth <= 768 && isHomePage)) {
      const handleScroll = () => {
        setIsHeaderAtTop(window.scrollY <= 150); // Adjust threshold as needed
      };
  
      window.addEventListener('scroll', handleScroll);
      
      // Call once on mount to set initial state
      handleScroll();
  
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);
  
  

  useEffect(() => {
    if (window.innerWidth > 768) {
      let lastScrollY = window.scrollY;

      const handleScroll = () => {
        const currentScrollY = window.scrollY;


        if (currentScrollY > lastScrollY) {
          setpastHeaderSection(true);
        } else {
          setpastHeaderSection(false);
        }

        lastScrollY = currentScrollY;
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [location]);


  return (
    <>
        <header className={`header ${!isHomePage ? 'header--border' : ''}`} id='header'>
          <div className={`headerlogo mobile ${isHeaderAtTop ? 'transparent' : ''} `}>
            <a href="/" >
              <img src="/assets/logo-header-mobile.svg" alt="Logo" />
            </a>
          </div>
          <div className={`nav-container  ${pastHeaderSection ? 'hidden' : ''} ${isHeaderAtTop ? 'transparent' : ''}`}>
            <nav>
                {!isHomePage && (
                  <div className="headerlogo">
                    <a href="/"  aria-label="Home Page">
                      <img src="/assets/logo-header.svg" alt="Logo" />
                    </a>
                  </div>
                )}
                <div className='header-nav-item'><a href='/about' aria-label="About page">About</a></div>
                <div className='header-nav-item'><a href='/portfolio'  aria-label="Portfolio page" >Portfolio</a></div>
                <div className='header-nav-item'><a href='/services' aria-label="Services page">Info</a></div>

                  {/* <div className='header-nav-item'>
                  <a href="https://calendly.com/photosbyamna/60min" target="_blank" rel="noreferrer">
                    Book Minis
                  </a>
                  </div> */}
                  <div className='header-nav-item'><a href='/blog'  aria-label="Blog Page">Blog</a></div>
                <div className='header-nav-item background'>
                  <ScrollToSectionLink to="contact">Inquire Now</ScrollToSectionLink>
                </div>
                <div className='social-group'>
                    <a className="social-link" href="https://www.instagram.com/galwhosnaps/?hl=en" target="_blank" rel="noreferrer"  aria-label="Instagram">
                      <img src="/assets/social-icons/instagram.svg" alt="Instagram" />
                    </a>
                    <a className="social-link" href="https://www.facebook.com/profile.php?id=61571146656233" target="_blank" rel="noreferrer"  aria-label="Facebook">
                      <img src="/assets/social-icons/facebook.svg" alt="Facebook" />
                    </a>
                    <a className="social-link" href="https://pin.it/7BwfsCyN5" target="_blank" rel="noreferrer"  aria-label="Pinterest">
                      <img src="/assets/social-icons/pinterest.svg" alt="Pinterest" />
                    </a>
                </div>
              {/* </div> */}
            </nav>
          </div>
          <div className={`hamburger ${pastHeaderSection ? '' : 'hidden'}`} onClick={toggleMenu}>MENU</div>
        </header>

      <div className={`side-nav-menu ${menuOpen ? 'is-active' : ''}`}>

        <nav>
        <button className="close-btn" onClick={toggleMenu} aria-label="Close">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
        <div className='side-nav-item' onClick={toggleMenu}>
            <a className='side-nav-link' href='/about'  aria-label="About Page">About</a>
          </div>
          <div className='side-nav-item' onClick={toggleMenu}>
            <a className='side-nav-link' href='/services' aria-label="Services Page" >Services</a>
          </div>
          <div className='side-nav-item' onClick={toggleMenu}>
          <a className='side-nav-link' href='/portfolio'  aria-label="Portfolio Page">Portfolio</a>
          </div>
          {/* <div
            className={`side-nav-item portfolio-nav ${sideNavDropdown ? 'open' : 'close'}`}
            onClick={() => setSideNavDropdown(!sideNavDropdown)}>Portfolio
              <div className={`nav-dropdown ${sideNavDropdown ? 'open' : 'close'}`}>
                <a onClick={() => handlePortfolioClick('Weddings')}>Weddings</a>
                <a onClick={() => handlePortfolioClick('Engagements')}>Engagements</a>
                <a onClick={() => handlePortfolioClick('Maternity')}>Maternity</a>
                <a onClick={() => handlePortfolioClick('Branding')}>Branding</a>
              </div>
          </div> */}
          <div className='side-nav-item' onClick={toggleMenu}>
              <a className='side-nav-link'  href="https://calendly.com/photosbyamna/60min" target="_blank" rel="noreferrer">
                Book Minis
              </a>
          </div>
          <div className='side-nav-item' onClick={toggleMenu}>
            <a className='side-nav-link'  href='/blog'  aria-label="Blog Page">Blog</a>
          </div>
          <div className='side-nav-item' onClick={toggleMenu}>
            <ScrollToSectionLink to="contact" className='side-nav-link'>Inquire</ScrollToSectionLink>
          </div>
        </nav>

        <div className='watermark-container'>
        <a href='/'>
          <div className="rotating-logo">
              <img className="camera" src="/assets/logo-camera-white.avif" alt="Camera" />
              <img className="text" src="/assets/logo-text-white.avif" alt="Text" />
          </div>
          </a>
          <div className='social-links light'>
            <a className='instagram' href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank' rel="noreferrer">INSTAGRAM</a>
            <a className='pinterest' href="https://pin.it/7BwfsCyN5" target='_blank' rel="noreferrer">PINTEREST</a>
            <a className='facebook' href="https://www.facebook.com/profile.php?id=61571146656233" target='_blank' rel="noreferrer">FACEBOOK</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
