import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toml from 'toml';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import '../../css/blog-template.css';
import { Helmet } from "react-helmet-async";

const BlogTemplate = () => {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const file = await import(`../../content/blogposts/${slug}.md`);
        const response = await fetch(file.default || file);
        const text = await response.text();
        
        const parts = text.split('---');
        if (parts.length > 1) {
          const frontmatter = parts[1];
          const markdownContent = parts.slice(2).join('---');

          const metadata = toml.parse(frontmatter);
          setMetadata(metadata);
          setContent(markdownContent);
        } else {
          console.error('TOML frontmatter missing in', slug);
        }
      } catch (error) {
        console.error('Error loading markdown:', error);
      }
    };
    
    loadPost();
  }, [slug]);

  if (!content) return <p>Loading...</p>;

  const pageTitle = metadata.title ? `${metadata.title} | Photos by Amna` : 'Blog Post | Photos by Amna';
  const pageDescription = metadata.description || 'Read the latest blog on Photos by Amna.';
  const pageURL = `https://photosbyamna.com/blog/${slug}`;
  const pageImage = metadata.image || 'https://www.photosbyamna.com/ogimage.jpg';

  return (
    <section className='blog-template-section'>
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <link rel="canonical" href={pageURL} />

      {/* Open Graph (Facebook) */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:image" content={pageImage} />
    </Helmet>
      <div>
        <h1>{metadata.title}</h1>
        

        <div className="blog-template-section-background">
        {metadata.featureImage && <img src={metadata.featureImage} alt={metadata.title} />}
        </div>
        <p>{new Date(metadata.date).toLocaleDateString()}</p>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </div>
    </section>
  );
};

export default BlogTemplate;
