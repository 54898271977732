import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import toml from 'toml';
import '../../css/blog-page.css'
import { Helmet } from "react-helmet-async";

// Import markdown files
const importMarkdownFiles = () => {
  const markdownContext = require.context('../../content/blogposts', false, /\.md$/);
  return markdownContext.keys().map((key) => ({
    path: key.replace('./', '').replace('.md', ''),
    file: markdownContext(key),
  }));
};



const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load
  }, []);
  
  useEffect(() => {
    const markdownFiles = importMarkdownFiles(); 

    const loadPosts = async () => {
      const loadedPosts = await Promise.all(
        markdownFiles.map(async ({ path, file }) => {
          const response = await fetch(file.default || file);

          const text = await response.text();

          const frontmatter = text.split('---')[1];

          try {
            const { title, date, excerpt, thumbnail } = toml.parse(frontmatter);
            return { title, date, excerpt, thumbnail, path};
          } catch (error) {
            console.error('Error parsing TOML for ${path}:', error);
            return {};
          }
        })
      );
      setPosts(loadedPosts.filter((post) => post.title));
    };

    loadPosts();
  }, []);

  return (
  <section className='blogPage'>
    <Helmet>
      <title>Photography Blog | Tips, Stories & Inspiration | Photos by Amna</title>
      <meta name="description" content="Explore photography stories, tips, and inspiration on our blog. From wedding shoots to creative techniques, find insights from Toronto based Photographer." />
      <meta name="keywords" content="photography blog, wedding photography, photography tips, creative photography, toronto photographer" />
      <meta name="author" content="Photos by Amna" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph */}
      <meta property="og:title" content="Photography Blog | Tips, Stories & Inspiration | Photos by Amna" />
      <meta property="og:description" content="Explore photography stories, tips, and inspiration on our blog. From wedding shoots to creative techniques, find insights from Toronto based Photographer." />
      <meta property="og:image" content="https://www.photosbyamna.com/ogimage.jpg" />
      <meta property="og:url" content="https://photosbyamna.com/blog" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://photosbyamna.com/blog" />
    </Helmet>
      <div>
        <div className='blogPage-heading fade-up'>
          <h1>BLOGS</h1>
          <h2>Explore Toronto Wedding Photography Tips, Locations, and Stories</h2>
          <p >Get expert photography tips, discover the top wedding spots in the Greater Toronto Area (GTA), and enjoy behind-the-scenes stories from real weddings and shoots. Whether you're in the midst of planning your big day or just looking for some inspiration, these blogs are packed with insights to help you capture beautiful memories through stunning photos.</p>
        </div>
        {posts.length > 0 ? (
          <div className='blog-grid fade-up'>
          {posts.map((post) => (
            <div key={post.path} className='blog-post'>
              <Link to={`/blog/${post.path}`}>
                {post.thumbnail && (
                  <div className='blog-post-thumbnail'>
                  <img 
                    src={post.thumbnail} 
                    alt={post.title}
                  />
                  </div>
                )}
                <div className='blog-post-text'>
                
                  <h3>{post.title}</h3>
                  <p>{post.excerpt}</p>
                  {/* <p className='blog-post-date'>{post.date}</p> */}
                </div>
              </Link>
            </div>
          ))};
          </div>
        ) : (
          <p>No posts available.</p> // Debug if nothing is loaded
        )}
      </div>
    </section>
  );
};

export default BlogPage;
