import React from 'react';

const ScrollToSectionLink = ({ to, children }) => {
  const handleClick = () => {
    const checkElementAndScroll = () => {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // Retry after a short delay if the element is not yet available
        setTimeout(checkElementAndScroll, 100);
      }
    };
    setTimeout(checkElementAndScroll, 100); // Initial delay to allow for rendering
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export default ScrollToSectionLink;